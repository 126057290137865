import { Paper, Stack, TextField, Typography, Box, Alert, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { blue } from '@mui/material/colors';
import { ExtLink, WaitButton } from 'components';
import { Form, mustBeEmail, notEmpty } from 'components/form';
import { AuthPage } from 'app/layout';
import { useAsyncState } from 'app/hooks';
import { useAppDispatch, signin } from 'app/store';
import type { Validate } from 'components/form';
import type { Credential } from 'app/store';
import { useLocation } from '@reach/router';
import abstractShapesBackground from './abstractShapesBackground.svg';
import { CobrandLogoWithPiP } from 'images/CobrandLogo';

const credentialValidator: Record<string, Validate> = {
  email: mustBeEmail(),
  password: notEmpty(),
};

export default function Signin() {
  const dispatch = useAppDispatch();
  const [signinState, success, failure, waiting] = useAsyncState();
  const windowLocation = useLocation();
  const showSignInForm = new URLSearchParams(windowLocation.search).get('form');

  const handleSumit = (data: Credential) => {
    dispatch(signin(data)).unwrap().then(success, failure);
    waiting();
  };

  return (
    <AuthPage>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          gap: 4,
          backgroundImage: `url(${abstractShapesBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {showSignInForm ? (
          <Paper sx={{ px: 4, py: 6, width: { xs: '90%', sm: 500 }, mt: 10 }}>
            <Box sx={{ textAlign: 'center', mb: 3 }}>
              <Typography variant="h5">SIGN IN</Typography>
              <Typography color="textSecondary" paragraph>
                With email and password
              </Typography>
              {signinState.status === 'failure' && (
                <Alert severity="error">{signinState.error.code || 'Signin failed'}</Alert>
              )}
            </Box>

            <Form<Partial<Credential>>
              initialData={{}}
              onSubmit={(data) => handleSumit(data as Credential)}
              validators={credentialValidator}
            >
              {(state, updateField) => {
                return (
                  <Stack spacing={3}>
                    <TextField
                      id="email"
                      value={state.data.email || ''}
                      onChange={(e) => updateField('email', e.target.value)}
                      error={Boolean(state.errors['email'])}
                      helperText={state.errors['email']}
                      required
                      fullWidth
                      label="Email"
                      autoComplete="username"
                    />
                    <TextField
                      id="password"
                      type="password"
                      value={state.data.password || ''}
                      onChange={(e) => updateField('password', e.target.value)}
                      error={Boolean(state.errors['password'])}
                      helperText={state.errors['password']}
                      required
                      fullWidth
                      label="Password"
                      autoComplete="current-password"
                    />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={state.data.remember}
                            onClick={() => updateField('rememberMe', !state.data.remember)}
                          />
                        }
                        label="Remember me on this computer"
                      />
                    </FormGroup>
                    <WaitButton id="submit" type="submit" variant="contained" wait={signinState.status === 'pending'}>
                      Sign in
                    </WaitButton>
                  </Stack>
                );
              }}
            </Form>
          </Paper>
        ) : null}
        <Box
          sx={{
            width: 'min-content',
            flexDirection: 'column',
            alignItems: 'center',
            display: 'flex',
            gap: 6,
          }}
        >
          <Paper sx={{ bgcolor: blue[50], px: { xs: 2, sm: 4 }, py: { xs: 4, sm: 6 }, width: { xs: '90vw', sm: 500 } }}>
            <Typography textAlign="center" variant="h5" sx={{ mb: 4 }}>
              Please sign in via your headspace account
            </Typography>
            <Typography textAlign="center">
              To access PiP, you first need to sign in to your headspace account, and then click “Go to PiP” on the PiP
              tile. Please sign in to your headspace account{' '}
              <ExtLink target="" href="https://my.headspace.org.au/">
                here
              </ExtLink>
              .
            </Typography>
          </Paper>
          <CobrandLogoWithPiP sx={{ width: '80%' }} />
        </Box>
      </Box>
    </AuthPage>
  );
}
